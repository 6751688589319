<template lang="pug">
.winners.container
  typeHeader(text="Winners" subtitle="Getaway")
  calendar(:months="months")
</template>

<script>
export default {
  name: "Sweeps",
  metaInfo: {
    title: "Winners"
  },
  props: {
    months: Array
  },
  components: {}
};
</script>

<style scoped lang="scss"></style>
